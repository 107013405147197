import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/flomesh.io/flomesh.io/src/layouts/Fixed/DocMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Pipy can start Pipy scripts from local file system or read from remote HTTP server. Starting from Pipy version `}<a parentName="p" {...{
        "href": "/release/histories/0.10.0-1/"
      }}>{`0.10.0-1`}</a>{`, it can read and execute scripts and configurations from `}<a parentName="p" {...{
        "href": "/operating/pipy-repo/"
      }}>{`Pipy Repo`}</a>{`.`}</p>
    <h2 {...{
      "id": "command-line-arguments"
    }}>{`Command line arguments`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`$ pipy -h
Usage: pipy [options] [<filename or URL>]

Options:
  -h, -help, --help                    Show help information
  -v, -version, --version              Show version information
  --log-level=<debug|info|warn|error>  Set the level of log output
  --verify                             Verify configuration only
  --reuse-port                         Enable kernel load balancing for all listening ports
  --admin-port=<port>                  Enable administration service on the specified port
  --admin-tls-cert=<filename>          Administration service certificate
  --admin-tls-key=<filename>           Administration service private key
  --admin-tls-trusted=<filename>       Client certificate(s) trusted by administration service
  --tls-cert=<filename>                Client certificate in communication to administration service
  --tls-key=<filename>                 Client private key in communication to administration service
  --tls-trusted=<filename>             Administration service certificate(s) trusted by client

`}</code></pre>
    <h3 {...{
      "id": "--log-level"
    }}><inlineCode parentName="h3">{`--log-level`}</inlineCode></h3>
    <p>{`Setting log level to any of : `}<inlineCode parentName="p">{`debug`}</inlineCode>{`、`}<inlineCode parentName="p">{`info`}</inlineCode>{`、`}<inlineCode parentName="p">{`warn`}</inlineCode>{`、`}<inlineCode parentName="p">{`error`}</inlineCode>{`.`}</p>
    <p>{`For example to configure the log level to `}<inlineCode parentName="p">{`debug`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`pipy --log-level=debug main.js
`}</code></pre>
    <h3 {...{
      "id": "--verify"
    }}><inlineCode parentName="h3">{`--verify`}</inlineCode></h3>
    <p>{`Verify the correctness of the proxy script file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`pipy()

.listen(8080)
  .serveHttP(
    msg => new Message(msg.body)
  )
`}</code></pre>
    <p>{`For example, above script has a typo in `}<inlineCode parentName="p">{`serverHTTP`}</inlineCode>{` filter and written as `}<inlineCode parentName="p">{`serveHttP`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`pipy --verify main.js

2022-02-16 13:07:58.523 [ERR] [pjs] Line 4:  .serveHttP(
2022-02-16 13:07:58.524 [ERR] [pjs]                    ^
2022-02-16 13:07:58.524 [ERR] [pjs] Error: not a function
2022-02-16 13:07:58.524 [ERR] [pjs] Backtrace:
2022-02-16 13:07:58.524 [ERR]     In (root) at line 4 column 13
2022-02-16 13:07:58.524 [INF] [shutdown] Shutting down...
2022-02-16 13:07:58.524 [INF] Stopped.
Done.
`}</code></pre>
    <h3 {...{
      "id": "--reuse-port"
    }}><inlineCode parentName="h3">{`--reuse-port`}</inlineCode></h3>
    <p>{`Enable kernel-level load balancing for all listening ports, refer to `}<inlineCode parentName="p">{`socket`}</inlineCode>{` option `}<inlineCode parentName="p">{`SO_REUSEPORT`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "--admin-port"
    }}><inlineCode parentName="h3">{`--admin-port`}</inlineCode></h3>
    <p>{`Configures pipy built-in `}<a parentName="p" {...{
        "href": "/operating/admin-gui/"
      }}>{`Admin Web Console`}</a>{` port. If you don't provide this command line option then default port which pipy admin console listens on is `}<inlineCode parentName="p">{`6060`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`$ pipy --admin-port=6080
2022-02-16 13:11:31.141 [INF] [admin] Starting admin service...
2022-02-16 13:11:31.141 [INF] [listener] Listening on port 6080 at ::

$ pipy
2022-02-16 13:11:53.533 [INF] [admin] Starting admin service...
2022-02-16 13:11:53.534 [INF] [listener] Listening on port 6060 at ::
`}</code></pre>
    <h2 {...{
      "id": "process-signals"
    }}>{`Process signals`}</h2>
    <p>{`Pipy process can receive and handle 3 kind of signals: `}<inlineCode parentName="p">{`SIGTSTP`}</inlineCode>{`、`}<inlineCode parentName="p">{`SIGHUP`}</inlineCode>{`、`}<inlineCode parentName="p">{`SIGINT`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ kill -SIGNAL PID
`}</code></pre>
    <h3 {...{
      "id": "sigtstp"
    }}><inlineCode parentName="h3">{`SIGTSTP`}</inlineCode></h3>
    <p>{`The Pipy process dumps the current process state on receiving `}<inlineCode parentName="p">{`SIGTSTP`}</inlineCode>{` signal.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`2022-02-16 14:11:53.533 [info] Received SIGTSTP, dumping...

CLASS                                                   #INSTANCES
--------------------------------------------------------------------
  Object                                                2
  pipy::Configuration                                   1
  pipy::Console                                         1
  pipy::Data                                            1
  pipy::Global                                          1
  pipy::Hessian                                         1
  pipy::Inbound                                         1
  pipy::JSON                                            1
  pipy::OS                                              1
  pipy::Pipy                                            1
  pipy::Pipy::Script                                    1
  pipy::Pipy::Store                                     1
  pipy::XML                                             1
  pipy::algo::Algo                                      1
  pipy::crypto::Crypto                                  1
  pipy::http::Http                                      1
  pjs::Constructor<pipy::Data>                          1
  pjs::Constructor<pipy::Message>                       1
  pjs::Constructor<pipy::MessageEnd>                    1
  pjs::Constructor<pipy::MessageStart>                  1
  pjs::Constructor<pipy::Netmask>                       1
  pjs::Constructor<pipy::Session>                       1
  pjs::Constructor<pipy::SessionEnd>                    1
  pjs::Constructor<pipy::URL>                           1
  pjs::Constructor<pipy::XML::Node>                     1
  pjs::Constructor<pipy::algo::Cache>                   1
  pjs::Constructor<pipy::algo::HashingLoadBalancer>     1
  pjs::Constructor<pipy::algo::LeastWorkLoadBalancer>   1
  pjs::Constructor<pipy::algo::Percentile>              1
  pjs::Constructor<pipy::algo::ResourcePool>            1
  pjs::Constructor<pipy::algo::RoundRobinLoadBalancer>  1
  pjs::Constructor<pipy::algo::URLRouter>               1
  pjs::Constructor<pipy::crypto::Certificate>           1
  pjs::Constructor<pipy::crypto::Cipher>                1
  pjs::Constructor<pipy::crypto::Decipher>              1
  pjs::Constructor<pipy::crypto::Hash>                  1
  pjs::Constructor<pipy::crypto::Hmac>                  1
  pjs::Constructor<pipy::crypto::JWK>                   1
  pjs::Constructor<pipy::crypto::JWT>                   1
  pjs::Constructor<pipy::crypto::PrivateKey>            1
  pjs::Constructor<pipy::crypto::PublicKey>             1
  pjs::Constructor<pipy::crypto::Sign>                  1
  pjs::Constructor<pipy::crypto::Verify>                1
  pjs::Constructor<pipy::http::File>                    1
  pjs::Constructor<pjs::Array>                          1
  pjs::Constructor<pjs::Boolean>                        1
  pjs::Constructor<pjs::Date>                           1
  pjs::Constructor<pjs::Number>                         1
  pjs::Constructor<pjs::Object>                         1
  pjs::Constructor<pjs::RegExp>                         1
  pjs::Constructor<pjs::String>                         1
  pjs::Function                                         1
TOTAL                                                   53

DATA              CURRENT(KB)  PEAK(KB)
-----------------------------------------
Codebase Service  0            0
Cipher            0            0
Decipher          0            0
Hmac              0            0
Hash              0            0
Sign              0            0
Verify            0            0
http.File         0            0
Codebase          4            4
Codebase Store    0            0
Unknown           0            0
Script            0            0
HTTP Encoder      0            0
TLS               0            0
Message           0            0
TOTAL             4            n/a

PIPELINE              #ALLOCATED  #ACTIVE
-------------------------------------------
  /main.js [:::8080]  0           0
TOTAL                 0           0

INBOUND  #CONNECTIONS  BUFFERED(KB)
-------------------------------------
8080     0/0           0
TOTAL    0             0

OUTBOUND  #CONNECTIONS  BUFFERED(KB)  #OVERFLOWED  MAX_CONN_TIME  AVG_CONN_TIME
---------------------------------------------------------------------------------
`}</code></pre>
    <h3 {...{
      "id": "sighup"
    }}><inlineCode parentName="h3">{`SIGHUP`}</inlineCode></h3>
    <p>{`Pipy process reloads the codebase when it receives the `}<inlineCode parentName="p">{`SIGHUP`}</inlineCode>{` signal.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ pipy main.js
2022-02-16 13:19:51.301 [INF] [config]
2022-02-16 13:19:51.302 [INF] [config] Module /main.js
2022-02-16 13:19:51.302 [INF] [config] ===============
2022-02-16 13:19:51.302 [INF] [config]
2022-02-16 13:19:51.302 [INF] [config]  [Listen on 8080 at 0.0.0.0]
2022-02-16 13:19:51.302 [INF] [config]  ----->|
2022-02-16 13:19:51.302 [INF] [config]        |
2022-02-16 13:19:51.302 [INF] [config]       serveHTTP
2022-02-16 13:19:51.302 [INF] [config]        |
2022-02-16 13:19:51.302 [INF] [config]  <-----|
2022-02-16 13:19:51.302 [INF] [config]
2022-02-16 13:19:51.302 [INF] [listener] Listening on port 8080 at 0.0.0.0
2022-02-16 13:20:39.102 [INF] [restart] Reloading codebase...
2022-02-16 13:20:39.102 [INF] [config]
2022-02-16 13:20:39.102 [INF] [config] Module /main.js
2022-02-16 13:20:39.102 [INF] [config] ===============
2022-02-16 13:20:39.102 [INF] [config]
2022-02-16 13:20:39.102 [INF] [config]  [Listen on 8080 at 0.0.0.0]
2022-02-16 13:20:39.102 [INF] [config]  ----->|
2022-02-16 13:20:39.102 [INF] [config]        |
2022-02-16 13:20:39.102 [INF] [config]       serveHTTP
2022-02-16 13:20:39.102 [INF] [config]        |
2022-02-16 13:20:39.102 [INF] [config]  <-----|
2022-02-16 13:20:39.102 [INF] [config]
2022-02-16 13:20:39.102 [INF] [restart] Codebase reloaded
`}</code></pre>
    <h3 {...{
      "id": "sigint"
    }}><inlineCode parentName="h3">{`SIGINT`}</inlineCode></h3>
    <p>{`Pipy process can be terminated by sending `}<inlineCode parentName="p">{`SIGINT`}</inlineCode>{` signal invoked via pressing `}<inlineCode parentName="p">{`CTRL + C`}</inlineCode>{` keys combination on your keyboard. Upon receiving `}<inlineCode parentName="p">{`SIGINT`}</inlineCode>{` signal, Pipy process will enter into terminating mode and no new connection requests will be entertained. If there are incomplete or in-progress pipelines, Pipy will wait for them to get completed. You can force exit by re-sending `}<inlineCode parentName="p">{`CTRL+C`}</inlineCode>{` keys combination to Pipy process.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      